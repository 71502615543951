import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { encryptData,decryptData } from '../encryptionUtils';
function Login(props) {
    const [email,setEmail]=useState("");
    const [password,setPassword]=useState("");
    const redireact=useNavigate();
    const admin=localStorage.getItem("admin");
    useEffect(()=>{
        if(admin){
            redireact("/admin")
        }
    },[admin])
    const Login=async(e)=>{
        e.preventDefault();
        let data={email,password};
        let res=await axios.post(`${props.url}/login-admin`,data);
        if(res.data){
            let data=res.data;
            if(data.admin){
                let endata=encryptData(data.name)
                localStorage.setItem("admin",endata);
                redireact("/admin")
            }else{
                toast.error("Invalid User")
            }
        }
    }
    return (
        <div className='container mt-5 border p-5 rounded'>
            <form onSubmit={Login}> 
                <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">Email address</label>
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={(e)=>setEmail(e.target.value)} value={email}></input>
                    <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
                </div>
                <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">Password</label>
                    <input type="password" class="form-control" id="exampleInputPassword1" onChange={(e)=>setPassword(e.target.value)} value={password}></input>
                </div>
                <button type="submit" class="btn btn-primary">Submit</button>
            </form>
            <ToastContainer />
        </div>
        
    )
}

export default Login