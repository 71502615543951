import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Sidebar from './Sidebar';
function Admin(props) {
    const [searchTerm, setSearchTerm] = useState('');
    const [users, setUsers] = useState([]);
    const admin = localStorage.getItem("admin");
    const redirect = useNavigate();
    // const users = [
    //     { name: 'John Doe', email: 'john@example.com', role: 'Admin' },
    //     { name: 'Jane Smith', email: 'jane@example.com', role: 'User' },
    //     { name: 'Mike Johnson', email: 'mike@example.com', role: 'User' },
    //     { name: 'Emily Davis', email: 'emily@example.com', role: 'Admin' }
    // ];
    useEffect(() => {
        if (admin) {
            const getDataUser = async () => {
                try {
                    let res = await axios.get(`${props.url}/userdata`);
                    if (res.data) {
                        setUsers(res.data);
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            };
            getDataUser();
        } else {
            redirect("/")
        }

    }, [props.url]);
    const handleToggleActive = async (index) => {
        const updatedUsers = [...users];
        const user = updatedUsers[index];
        user.active = user.active === 'yes' ? 'no' : 'yes';
        setUsers(updatedUsers);

        try {
            let data = await axios.put(`${props.url}/userdata/${user.id}`, { active: user.active });
            console.log(data);
        } catch (error) {
            console.error('Error updating user data:', error);
        }
    };
    const filteredUsers = users.filter(user =>
        user.frm_name.toLowerCase().includes(searchTerm.toLowerCase()) || user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const handleLogout = () => {
        localStorage.removeItem("admin");
        redirect("/");
    };

    return (
        <div className="container-fluid">
            <div className="row">
                {/* Sidebar */}
                {/* <div className="col-md-2 p-0"> */}
                    {/* <div className="bg-light border-right vh-100 " id="sidebar-wrapper"> */}
                        {/* <div className="list-group list-group-flush"> */}
                            {/* <a href="/" className="list-group-item list-group-item-action bg-light">Dashboard</a>
                            <a href="/users" className="list-group-item list-group-item-action bg-light">Users</a>
                            <a href="/settings" className="list-group-item list-group-item-action bg-light">Settings</a> */}
                            {/* Add more sidebar items as needed */}
                            {/* <div className='p-5'> */}
                           
                            {/* </div> */}
                        {/* </div> */}
                    {/* </div> */}
                {/* </div> */}

                {/* Main Content */}
                <div className="col-md-12 p-0">
                    {/* Navbar */}
                    <nav class="navbar navbar-expand-lg navbar-light bg-light">
                        <div class="container-fluid">
                            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                            </button>
                            <a class="navbar-brand" href="#">Navbar</a>
                            <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
                                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                    <li class="nav-item">
                                        {/* <a class="nav-link active" aria-current="page" href="#">Home</a> */}
                                    </li>
                                    <li class="nav-item">
                                        {/* <a class="nav-link" href="#">Link</a> */}
                                    </li>
                                    <li class="nav-item">
                                        {/* <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a> */}
                                    </li>
                                </ul>
                                <form class="d-flex">
                                    {/* <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search"></input> */}
                                    <button className="btn btn-outline-danger" onClick={handleLogout}>
                                Logout
                            </button>
                                </form>
                            </div>
                        </div>
                    </nav>

                    {/* Main Content */}
                    <div className="container mt-5">
                        <h1 className="text-center mb-4">Admin Panel</h1>
                        <div className="mb-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search for users..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <table className="table table-bordered table-striped">
                            <thead className="thead-dark">
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Active</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredUsers.map((user, index) => (
                                    <tr key={index}>
                                        <td>{user.frm_name}</td>
                                        <td>{user.email}</td>
                                        <td>
                                            <div className="form-check form-switch">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    id={`flexSwitchCheckDefault-${index}`}
                                                    checked={user.active === 'yes'}
                                                    onChange={() => handleToggleActive(index)}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Admin