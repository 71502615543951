import React from 'react'
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './User/Login';
import Admin from './Admin/Admin';

function RoutesFile() {
    const url="https://subdomain.rupela.in";
    return (
        <div>
           <BrowserRouter>
            <Routes>
                <Route path='/' element={<Login url={url}/>}></Route>
                <Route path='/admin' element={<Admin url={url}/>}></Route>
            </Routes>
           </BrowserRouter>
        </div>
    )
}

export default RoutesFile